import React from 'react';

import Popup from 'reactjs-popup';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/Layout';
import '../assets/css/our-home.css';

const OurHomePage = ({ location }) => {
  const query = useStaticQuery(graphql`
    query {
      living1: file(relativePath: { eq: "our-home/living-1.jpg" }) {
        ...horizontalFluidImage
      }
      living3: file(relativePath: { eq: "our-home/living-3.jpg" }) {
        ...horizontalFluidImage
      }
      kitchen1: file(relativePath: { eq: "our-home/kitchen-1.jpg" }) {
        ...horizontalFluidImage
      }
      bedroom1: file(relativePath: { eq: "our-home/bedroom.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      window: file(relativePath: { eq: "our-home/window.jpg" }) {
        ...horizontalFluidImage
      }
      fromRuelle: file(relativePath: { eq: "our-home/from-ruelle.jpg" }) {
        ...horizontalFluidImage
      }
      kitchenUtilities: file(
        relativePath: { eq: "our-home/kitchen-utilities.jpg" }
      ) {
        ...horizontalFluidImage
      }
      bathroomP: file(relativePath: { eq: "our-home/bathroom-p.jpg" }) {
        ...portraitFluidImage
      }
      hallwayFromKitchenP: file(
        relativePath: { eq: "our-home/hallway-from-kitchen-p.jpg" }
      ) {
        ...portraitFluidImage
      }
      laundryFar: file(relativePath: { eq: "our-home/laundry-far.jpg" }) {
        ...horizontalFluidImage
      }
      living2: file(relativePath: { eq: "our-home/living-2.jpg" }) {
        ...horizontalFluidImage
      }
    }
  `);

  return (
    <Layout location>
      <div id="all">
        <div className="container header">
          <div className="is-divider"></div>
          <nav className="level">
            <p className="level-item has-text-centered">
              <Link className="link is-info" to="/">
                cOLO&#8580;
              </Link>
            </p>
            <p className="level-item has-text-centered">LEASE TRANSFER</p>
            <p className="level-item has-text-centered">
              FABULOUS 1BR APARTMENT LE PLATEAU/MONT ROYAL - MID-FEB
            </p>
            <p className="level-item has-text-centered is-hidden">
              FR / ES / ENG
            </p>
          </nav>
        </div>
        <div className="container br-top-bottom">
          <div className="is-divider"></div>
          <div className="columns is-mobile">
            <div className="column top-image">
              <Img
                className="pad"
                fluid={query.living1.childImageSharp.fluid}
                alt="living"
              />
              <p className="is-hidden-mobile has-text-centered">
                Welcome to our home!
              </p>
            </div>
            <div className="column top-image br-right">
              <Img
                className="pad"
                fluid={query.living3.childImageSharp.fluid}
                alt="hallway"
              />
              <p className="is-hidden-mobile has-text-centered">
                Down the hallway we have a closet for your coat
              </p>
            </div>
            <div className="column top-image">
              <Img
                className="pad"
                fluid={query.kitchen1.childImageSharp.fluid}
                alt="kitchen"
              />
              <p className="is-hidden-mobile has-text-centered">
                and our favourite room of the house.
              </p>
            </div>
          </div>
          <div className="is-divider"></div>
        </div>
        <div className="container">
          <div className="columns is-mobile">
            <div className="column is-8 is-offset-2 has-text-centered">
              <h1 id="gold-title">3 1/2 TO RENT</h1>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="columns is-mobile">
            <div className="column is-8 is-offset-2">
              <Img fluid={query.bedroom1.childImageSharp.fluid} alt="bedroom" />
            </div>
          </div>
          <div className="is-divider"></div>
        </div>
        <div className="container">
          <div className="columns">
            <div className="column is-three-fifths section-text">
              <p className="subtitle">Let's get you settled in :)</p>
              <p>
                Our tour starts at the front entrance to the house which leads
                into the living room. We have a couch in here and our beloved
                Hexagon.
              </p>
              <p>
                This room is North-East facing and gets sunshine in the morning.
                We have Sapina, our xmas tree in the corner by the couch here
                but you can replace her with a C02 breathing friend.
              </p>
              <p>
                It would be pretty cool to set up a projector screen on the wall
                with the hat, fan and photos!
              </p>
            </div>
            <div className="column section-text">
              <p className="subtitle">
                Why do we call it <Link to="/">cOLO&#8580;</Link>?
              </p>
              <p>
                We like this shortening of colocataire/colocation although in
                this case we are transferring our lease, so we will only be your{' '}
                <Link to="/">cOLO&#8580;</Link>s in spirit :)
              </p>
              <p>
                Soon <Link to="/">cOLO&#8580;</Link> will be something that
                helps you find that piece of info that makes a new town a home.
                But for now we thought that we could share our flat as a house
                listing here, and the welcome that we will give with it.
              </p>
            </div>
          </div>
          <div className="is-divider"></div>
        </div>
        <div className="container">
          <h1 className="title has-text-centered">
            Are you going to be the next one in our flat?
          </h1>
          <div className="columns is-mobile">
            <div className="column is-three-fifths section-text">
              <p>
                1 bedroom, 1 living room, 1 big kitchen with dining space, 1
                laundry/studio room, 1 bathroom.
              </p>
              <p>
                Sunny all day. Main door to Rue de la Roche, back door to super
                cute ruelle Poitevin.
              </p>
              <p>Rent is $865 per month.</p>
              <p>
                The apartment is well heated -bill not included in the rental-
                and we have a couple of fans that work perfectly for summer.
              </p>
              <p>
                - 5 minute walk to buses 11, 97, 14, 427 and a 10 minute walk to
                Metro Mont Royal/Laurier
                <br />- 5 minute walk to Parc Laurier and a 10 minute walk to
                Parc La Fontaine
              </p>
              <p>
                Amazing area close to excellent bagels, many cafes, restaurants
                and shops.
              </p>
              <p>
                Close to supermarkets: Intermarche, Metro, several
                fruit/convenience stores and there's the Dep on the corner.
              </p>
              <p>
                Priority to those who will buy our beloved furniture and
                appliances, we can provide everything you need to just bring
                your luggage and settle.
              </p>
              <p>
                All furnished washing machine/dryer, queen mattress and bed with
                storage, night tables, sofa bed for guests, oak coffee table,
                stupendous oak dining table, shelves... Stove and fridge
                provided by the land lady.
              </p>
              <p>
                We leave Montreal after more than a year and sadly we need to
                say goodbye to our beautiful nest, so we would like to help our
                land-lady find somebody to tak over this beautiful apartment.
              </p>
              <p>
                Visits between Jan 17th and Jan 22nd, we will also do our best
                to arrange visits on the weekends before.
              </p>
              <div className="columns is-mobile">
                <div className="column is-4 is-offset-4">
                  <Popup
                    trigger={<button>Contact Us!</button>}
                    position="right center"
                  >
                    <div>
                      <p>Hey, our apartment has a new cOLO&#8580; now.</p>
                      <p>
                        We hope that you found your place in Montreal. If you
                        would like some recommendations head over to our
                        Montreal post :)
                      </p>
                    </div>
                  </Popup>
                </div>
              </div>
              <div className="columns is-hidden-mobile">
                <div className="column">
                  <Img fluid={query.window.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={query.fromRuelle.childImageSharp.fluid} />
                </div>
                <div className="column">
                  <Img fluid={query.kitchenUtilities.childImageSharp.fluid} />
                </div>
              </div>
            </div>
            <div className="column">
              <div>
                <Img
                  fluid={query.hallwayFromKitchenP.childImageSharp.fluid}
                  alt="laundry"
                />
              </div>
              <div className="is-divider"></div>
              <div>
                <Img
                  fluid={query.bathroomP.childImageSharp.fluid}
                  alt="bathroom"
                />
              </div>
              <div className="is-divider is-hidden-desktop"></div>
              <div className="is-hidden-desktop">
                <Img fluid={query.window.childImageSharp.fluid} alt="window" />
              </div>
              <div className="is-divider is-hidden-desktop"></div>
              <div className="is-hidden-desktop">
                <Img
                  fluid={query.fromRuelle.childImageSharp.fluid}
                  alt="ruelle"
                />
              </div>
              <div className="is-divider is-hidden-desktop"></div>
              <div className="is-hidden-desktop">
                <Img
                  fluid={query.kitchenUtilities.childImageSharp.fluid}
                  alt="ruelle"
                />
              </div>
            </div>
          </div>
        </div>
        <div id="carousel-modal" className="modal">
          <div className="modal-background"></div>
          <div className="modal-content">
            <div className="carousel carousel-animated carousel-animate-slide">
              <div className="carousel-container">
                <div
                  id="/imgs/living-1.jpg"
                  className="carousel-item has-background"
                >
                  <img
                    className="is-background"
                    src="/imgs/living-1.jpg"
                    alt=""
                    width="1040"
                    height="780"
                  />
                  <div className="title">
                    Living room - sunny in the morning
                  </div>
                </div>
                <div
                  id="/imgs/living-3.jpg"
                  className="carousel-item has-background"
                >
                  <img
                    className="is-background"
                    src="/imgs/living-3.jpg"
                    alt=""
                    width="1040"
                    height="780"
                  />
                  <div className="title">Living room hallway</div>
                </div>
                <div
                  id="/imgs/kitchen-1.jpg"
                  className="carousel-item has-background"
                >
                  <img
                    className="is-background"
                    src="/imgs/kitchen-1.jpg"
                    alt=""
                    width="1040"
                    height="780"
                  />
                  <div className="title">Kitchen - sunny in the afternoon</div>
                </div>
                <div
                  id="/imgs/bedroom.jpg"
                  className="carousel-item has-background"
                >
                  <img
                    className="is-background"
                    src="/imgs/bedroom.jpg"
                    alt=""
                    width="1040"
                    height="780"
                  />
                  <div className="title">
                    Bedroom - connected to the living room
                  </div>
                </div>
                <div
                  id="/imgs/laundry-far.jpg"
                  className="carousel-item has-background"
                >
                  <img
                    className="is-background"
                    src="/imgs/laundry-far.jpg"
                    alt=""
                    width="1040"
                    height="780"
                  />
                  <div className="title">
                    Laundry - connected to the kitchen
                  </div>
                </div>
                <div
                  id="/imgs/living-2.jpg"
                  className="carousel-item has-background"
                >
                  <img
                    className="is-background"
                    src="/imgs/living-2.jpg"
                    alt=""
                    width="1040"
                    height="780"
                  />
                  <div className="title">Living room</div>
                </div>
              </div>
              <div className="carousel-navigation">
                <div className="carousel-nav-left">
                  <i className="fa fa-chevron-left" aria-hidden="true"></i>
                </div>
                <div className="carousel-nav-right">
                  <i className="fa fa-chevron-right" aria-hidden="true"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default OurHomePage;
